import type { ReactNode } from "react";
import { Link, useRouteLoaderData } from "@remix-run/react";
import {
  AccessibleIcon,
  Flex,
  Heading,
  IconButton,
  Tooltip,
  Theme,
  Avatar,
  Text,
} from "@radix-ui/themes";
import { NavLink, useLocation, type NavLinkProps } from "@remix-run/react";
import styles from "./Header.module.css";
import { BoxLink } from "./BoxLink";
import { ThemeToggle } from "./ThemeToggle";
import { QuickLinks } from "./QuickLinks";
import {
  GitHubLogoIcon,
  ExitIcon,
  RocketIcon,
  PersonIcon,
} from "@radix-ui/react-icons";
import { classNames } from "~/lib/utils";
import { Icons } from "./Icons";
import { Roles, Action } from "~/lib/session";
import { WithAction } from "./WithAction";
import { ElementLocation, ElementType } from "~/services/segment-types";
import { segmentTrackClick, segmentTrackLinkClick } from "~/lib/segment-utils";
import type { loader as rootLoader } from "~/routes/_app";

export interface HeaderProps {
  children?: ReactNode;
  roles: Roles[];
}
export const Header = ({ children, roles }: HeaderProps) => {
  const location = useLocation();
  const { user } = useRouteLoaderData<typeof rootLoader>("routes/_app") || {};

  return (
    <Theme asChild>
      <div className={classNames(styles.HeaderRoot)}>
        <div className={styles.HeaderInner}>
          <Flex justify="between" asChild>
            <div
              style={{
                position: "absolute",
                height: "inherit",
                top: 0,
                left: 0,
                right: 0,
              }}
            >
              <Flex align="center" pl="4" className=" w-1/3">
                <BoxLink
                  to="/"
                  onClick={(event: React.SyntheticEvent) =>
                    segmentTrackLinkClick(
                      event.target,
                      ElementLocation.MainNavigation,
                    )
                  }
                >
                  <Flex align="center" gap="2">
                    <AccessibleIcon label="Vendr Backoffice">
                      <Icons.vendr height="25" color="var(--accent-9)" />
                    </AccessibleIcon>
                    <Heading>Backoffice</Heading>
                  </Flex>
                </BoxLink>
              </Flex>

              <div className={styles.HeaderProductLinksContainer}>
                <HeaderProductLink to="/dashboard">Dashboard</HeaderProductLink>
                <HeaderProductLink to="/catalog">Catalog</HeaderProductLink>
                <HeaderProductLink to="/documents">Documents</HeaderProductLink>
                <HeaderProductLink to="/tasks">Tasks</HeaderProductLink>
                <HeaderProductLink to="/reports">Reports</HeaderProductLink>
                {roles.includes(Roles.Admin) && (
                  <HeaderProductLink to="/vibe">VIBE</HeaderProductLink>
                )}
                <HeaderProductLink to="/tools">Tools</HeaderProductLink>
              </div>

              <Flex
                align="center"
                gap="5"
                pr="4"
                className="w-1/3"
                justify="end"
              >
                {children}

                <QuickLinks />

                <Tooltip content="View ProductBoard Portal">
                  <IconButton
                    asChild
                    size="3"
                    variant="ghost"
                    color={
                      location.pathname === "/productboard-portal"
                        ? "iris"
                        : "gray"
                    }
                  >
                    <Link
                      to="/productboard-portal"
                      onClick={() =>
                        segmentTrackClick(
                          "View ProductBoard Portal",
                          ElementType.Link,
                          ElementLocation.MainNavigation,
                        )
                      }
                    >
                      <RocketIcon width="16" height="16" />
                    </Link>
                  </IconButton>
                </Tooltip>

                <Tooltip content="View GitHub">
                  <IconButton asChild size="3" variant="ghost" color="gray">
                    <Link
                      to="https://github.com/vendrinc/backoffice"
                      target="_blank"
                      rel="noreferrer"
                      onClick={() =>
                        segmentTrackClick(
                          "View GitHub",
                          ElementType.Link,
                          ElementLocation.MainNavigation,
                        )
                      }
                    >
                      <GitHubLogoIcon width="16" height="16" />
                    </Link>
                  </IconButton>
                </Tooltip>

                <ThemeToggle />

                <Tooltip
                  content={
                    <Flex direction="column" gap="2">
                      <Flex direction="row" gap="2">
                        <Text size="2" weight="bold">
                          Logged as:
                        </Text>
                        <Text size="2">{user?.email}</Text>
                      </Flex>
                      <Flex direction="row" gap="2">
                        <Text size="2" weight="bold">
                          Roles:
                        </Text>
                        <Text size="2">{roles.join(", ")}</Text>
                      </Flex>
                    </Flex>
                  }
                >
                  <IconButton asChild size="3" variant="ghost" color="gray">
                    <PersonIcon width="16" height="16" />
                  </IconButton>
                </Tooltip>

                <Tooltip content="Logout">
                  <IconButton asChild size="3" variant="ghost" color="gray">
                    <Link
                      to="/logout"
                      onClick={() =>
                        segmentTrackClick(
                          "Logout",
                          ElementType.Link,
                          ElementLocation.MainNavigation,
                        )
                      }
                    >
                      <ExitIcon width="16" height="16" />
                    </Link>
                  </IconButton>
                </Tooltip>
              </Flex>
            </div>
          </Flex>
        </div>
      </div>
    </Theme>
  );
};

const HeaderProductLink = ({ children, ...props }: NavLinkProps) => (
  <NavLink
    className={styles.HeaderProductLink}
    {...props}
    prefetch="intent"
    onClick={(event: React.SyntheticEvent) =>
      segmentTrackLinkClick(event.target, ElementLocation.MainNavigation)
    }
  >
    <span className={styles.HeaderProductLinkInner}>
      {children as ReactNode}
    </span>
    <span className={styles.HeaderProductLinkInnerHidden}>
      {children as ReactNode}
    </span>
  </NavLink>
);
