import { Text, Flex } from "@radix-ui/themes";
import { EntityRevisionStatus } from "@prisma-app/client";
import { CancelButton, SaveButton } from "./Buttons";

export type Task = Partial<{
  id: string;
  deletedAt: Date | null;
  createdAt: Date;
  updatedAt: Date;
  requesterId: string;
  requesterType: string;
  assignedTo: string;
  state: EntityRevisionStatus;
  taskType: string;
}>;

type TicketWidgetTaskFormProps = {
  onCancel?: () => void;
};

export function TicketWidgetTaskForm({ onCancel }: TicketWidgetTaskFormProps) {
  return (
    <Flex direction="column">
      <h3 className="text-lg font-semibold pb-2">Create a new task</h3>
      <Text>Create a SKU mapping or Data validation task</Text>
      <div>FORM DATA</div>
      <Flex direction="row" gap="2" justify="end">
        <CancelButton onClick={() => onCancel?.()} />
        <SaveButton />
      </Flex>
    </Flex>
  );
}
