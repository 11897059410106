import { useState } from "react";
import {
  Box,
  Text,
  Tabs,
  Card,
  Flex,
  ScrollArea,
  IconButton,
  Tooltip,
  Avatar,
  Badge,
  Separator,
} from "@radix-ui/themes";
import { PlusIcon } from "@radix-ui/react-icons";
import {  } from "@remix-run/server-runtime";
import { EntityRevisionStatus } from "@prisma-app/client";
import { getInitials } from "~/lib/utils";
import { TicketWidgetTaskList } from "./TicketWidgetTaskList";
import { TicketWidgetTaskForm } from "./TicketWidgetTaskForm";
import { loader } from "~/routes/_app";
import type { loader as CompanyLoader } from "~/routes/_app.catalog.companies_._nav.$companyId";

type Company = Awaited<ReturnType<typeof CompanyLoader>>["company"];
type PageTasks = Awaited<ReturnType<typeof CompanyLoader>>["pageTasks"];
type User = Awaited<ReturnType<typeof loader>>["user"];

type TicketWidgetTabContentProps = {
  company: Company;
  tasks: PageTasks;
  user: User;
};

export function TicketWidgetTabContent({
  company,
  tasks,
  user,
}: TicketWidgetTabContentProps) {
  const [, setTicketInProgress] = useState(false);
  const [createTask, setCreateTask] = useState(false);

  // TODO: Update this once we are not using dummy data
  const myTasks = tasks.filter(
    (task) =>
      (task.assignedTo === user.email ||
        task.assignedTo === "foo.bar@vendr.com") &&
      task.state == EntityRevisionStatus.IN_REVIEW,
  );

  // TODO: Update this once we are not using dummy data
  const pageTasks = tasks.filter(
    (task) =>
      task.state !== EntityRevisionStatus.IN_REVIEW &&
      task.state !== EntityRevisionStatus.REVIEWED &&
      task.state !== EntityRevisionStatus.AFFIRMED,
  );

  return (
    <Card className="shadow-2xl">
      <Tabs.Root
        defaultValue="home"
        className="w-[400px] h-[600px] h-max-[700px]"
      >
        <Flex direction="column" justify="between" gap="1" className="h-full">
          <Box className="h-[calc(100%-60px)]">
            <Tabs.Content value="home" className="h-full">
              <Flex direction="column" gap="2" height="100%">
                <Flex direction="row" justify="between" align="center">
                  <h2 className="text-2xl font-semibold leading-none tracking-tight">
                    My Tasks
                  </h2>
                  <Avatar
                    size="3"
                    radius="full"
                    color="violet"
                    fallback={getInitials(user.name)}
                  />
                </Flex>
                <Separator size="4" />
                <ScrollArea scrollbars="both">
                  <Flex direction="column" gap="2" height="100%">
                    {myTasks.length ? (
                      <Box>
                        <h3 className="text-lg font-semibold pb-2">
                          Tasks in progress for {company.name}
                        </h3>
                        <TicketWidgetTaskList
                          tasks={myTasks}
                          onStartTask={() => {
                            setTicketInProgress(true);
                          }}
                        />
                      </Box>
                    ) : (
                      <Flex
                        direction="column"
                        align="center"
                        justify="center"
                        height="100%"
                        mb="2"
                      >
                        <h3 className="text-lg font-semibold pb-2">
                          No tasks in review
                        </h3>
                        <Text>
                          Your tasks in review for <em>{company.name}</em> will
                          be shown here
                        </Text>
                      </Flex>
                    )}
                  </Flex>
                </ScrollArea>
              </Flex>
            </Tabs.Content>

            <Tabs.Content value="tasks" className="h-full">
              <Flex direction="column" gap="2" height="100%">
                <Flex direction="row" justify="between" align="center">
                  <h2 className="text-2xl font-semibold leading-none tracking-tight">
                    Page Tasks
                  </h2>
                  <Tooltip content="Create a new task">
                    <IconButton
                      size="3"
                      radius="full"
                      className="cursor-pointer"
                      onClick={() => setCreateTask(true)}
                    >
                      <PlusIcon />
                    </IconButton>
                  </Tooltip>
                </Flex>
                <Separator size="4" />
                <ScrollArea scrollbars="both">
                  {createTask ? (
                    <TicketWidgetTaskForm
                      onCancel={() => setCreateTask(false)}
                    />
                  ) : pageTasks.length ? (
                    <>
                      <Flex
                        direction="row"
                        justify="between"
                        align="center"
                        mb="2"
                      >
                        <h3 className="text-lg font-semibold">
                          All Tasks for {company.name}
                        </h3>
                        <Text>
                          Total:{" "}
                          <Badge size="3" radius="full">
                            {pageTasks.length}
                          </Badge>
                        </Text>
                      </Flex>

                      <TicketWidgetTaskList
                        tasks={pageTasks}
                        onStartTask={() => setTicketInProgress(true)}
                      />
                    </>
                  ) : (
                    <Flex
                      direction="column"
                      align="center"
                      justify="center"
                      height="100%"
                      mb="2"
                    >
                      <h3 className="text-lg font-semibold">
                        No available tasks
                      </h3>
                      <Text>
                        All available tasks for <em>{company.name}</em> will be
                        shown here
                      </Text>
                    </Flex>
                  )}
                </ScrollArea>
              </Flex>
            </Tabs.Content>
          </Box>
          <Separator size="4" />
          <Tabs.List className="flex justify-between shadow-none *:flex-initial *:cursor-pointer gap-2 h-[40px] flex-shrink-0">
            <Tabs.Trigger
              value="home"
              className="w-full *:w-full data-[state=active]:before:content-none *:data-[state=active]:bg-[var(--accent-9)] *:data-[state=active]:text-white"
            >
              My Tasks
            </Tabs.Trigger>
            <Tabs.Trigger
              value="tasks"
              className="w-full *:w-full data-[state=active]:before:content-none *:data-[state=active]:bg-[var(--accent-9)] *:data-[state=active]:text-white"
            >
              Page Tasks
            </Tabs.Trigger>
          </Tabs.List>
        </Flex>
      </Tabs.Root>
    </Card>
  );
}
