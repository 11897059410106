import { type LoaderFunctionArgs } from "@remix-run/node";
import { requireUserSession } from "~/lib/session-utils.server";
import { Outlet, useLoaderData, useMatches } from "@remix-run/react";
import { Header } from "~/components/Header";
import { Footer } from "~/components/Footer";
import { TicketWidget } from "~/components/TicketWidget";
import * as Sentry from "@sentry/remix";
import type { RouteOptions } from "~/types";
import { useSegmentIdentify, useSegmentPage } from "~/lib/segment-utils";
import { getSession } from "~/services/sessions.server";

export async function loader({ request }: LoaderFunctionArgs) {
  const user = await requireUserSession(request);
  const session = await getSession(request);

  let title = "";
  if (session.has("userInfo")) {
    const userInfo = session.get("userInfo");
    title = userInfo.title;
  }

  return { user, title };
}

export default function AppLayout() {
  const { user, title } = useLoaderData<typeof loader>();

  useSegmentIdentify(user, title);
  useSegmentPage();

  const matches = useMatches();
  const currentMatch = matches[matches.length - 1];
  const options = currentMatch.handle as RouteOptions | undefined;
  const hideFooter = options?.hideFooter;

  Sentry.setUser({
    id: user.sub,
    email: user.email,
  });

  return (
    <>
      <Header roles={user.roles} />
      <main
        style={{
          minHeight: `calc(100vh - var(--header-height)${hideFooter ? "" : "- var(--footer-height)"})`,
        }}
      >
        <Outlet />
        <TicketWidget />
      </main>
      {!hideFooter && <Footer />}
    </>
  );
}
