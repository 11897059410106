import * as React from "react";
import { Link, type LinkProps } from "@remix-run/react";
import styles from "./BoxLink.module.css";
import { classNames } from "~/lib/utils";

export const BoxLink = React.forwardRef<HTMLAnchorElement, LinkProps>(
  function BoxLink({ className, children, ...props }, forwardedRef) {
    return (
      <Link
        ref={forwardedRef}
        className={classNames(styles.BoxLink, className)}
        {...props}
      >
        {children}
      </Link>
    );
  },
);
