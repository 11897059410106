import { Card, Flex, Button, DataList } from "@radix-ui/themes";
import { cn } from "~/lib/utils";
import { EntityRevisionStatusBadge } from "./EntityRevisionStatusBadge";
import { EntityRevisionStatus } from "@prisma-app/client";
import type { loader as CompanyLoader } from "~/routes/_app.catalog.companies_._nav.$companyId";

type PageTasks = Awaited<ReturnType<typeof CompanyLoader>>["pageTasks"];

type TicketWidgetTaskListProps = {
  tasks: PageTasks;
  onStartTask?: (task: PageTasks[number]) => void;
};

export function TicketWidgetTaskList({
  tasks,
  onStartTask,
}: TicketWidgetTaskListProps) {
  return (
    <ul className="*:mb-2">
      {tasks?.map((task) => (
        <li key={task.id}>
          <Card>
            <Flex direction="row" justify="between" gap="4">
              <DataList.Root className="gap-2">
                <DataList.Item>
                  <DataList.Label minWidth="80px">Task Type</DataList.Label>
                  <DataList.Value>{task.taskType}</DataList.Value>
                  <DataList.Label minWidth="80px">Assigned To</DataList.Label>
                  <DataList.Value>{task.assignedTo}</DataList.Value>
                  <DataList.Label minWidth="80px">Status</DataList.Label>
                  <DataList.Value>
                    <EntityRevisionStatusBadge
                      status={task.state as EntityRevisionStatus}
                    />
                  </DataList.Value>
                </DataList.Item>
              </DataList.Root>
              <Button
                className={cn({
                  "cursor-pointer":
                    task.state !== EntityRevisionStatus.IN_REVIEW &&
                    task.state !== EntityRevisionStatus.AFFIRMED &&
                    task.state !== EntityRevisionStatus.REVIEWED,
                })}
                disabled={
                  task.state === EntityRevisionStatus.IN_REVIEW ||
                  task.state === EntityRevisionStatus.AFFIRMED ||
                  task.state === EntityRevisionStatus.REVIEWED
                }
                onClick={() => {
                  // TODO: For demonstration purposes only
                  task.state = EntityRevisionStatus.IN_REVIEW;
                  onStartTask?.(task);
                }}
              >
                Start
              </Button>
            </Flex>
          </Card>
        </li>
      ))}
    </ul>
  );
}
