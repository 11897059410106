import { Link } from "@remix-run/react";
import { DropdownMenuIcon } from "@radix-ui/react-icons";
import { DropdownMenu, IconButton } from "@radix-ui/themes";
import { ElementLocation, ElementType } from "~/services/segment-types";
import { segmentTrackClick, segmentTrackLinkClick } from "~/lib/segment-utils";

export function QuickLinks() {
  return (
    <DropdownMenu.Root
      onOpenChange={(open: boolean) => {
        if (open) {
          segmentTrackClick(
            "Quick Links",
            ElementType.Dropdown,
            ElementLocation.MainNavigation,
          );
        }
      }}
    >
      <DropdownMenu.Trigger>
        <IconButton
          className="IconButton"
          aria-label="Quick Links"
          variant="ghost"
          color="gray"
        >
          <DropdownMenuIcon width="16" height="16" />
        </IconButton>
      </DropdownMenu.Trigger>

      <DropdownMenu.Content className="DropdownMenuContent">
        <DropdownMenu.Item asChild>
          <Link
            to="https://vendrco.slack.com/archives/C06LP3G8BNF"
            target="_blank"
            rel="noopener noreferrer"
            onClick={(event) => {
              segmentTrackLinkClick(
                event.currentTarget,
                ElementLocation.QuickLinksMenu,
              );
            }}
          >
            #vendr-strategy-updates
          </Link>
        </DropdownMenu.Item>
        <DropdownMenu.Item asChild>
          <Link
            to="https://vendr.atlassian.net/wiki/home"
            target="_blank"
            rel="noopener noreferrer"
            onClick={(event) => {
              segmentTrackLinkClick(
                event.currentTarget,
                ElementLocation.QuickLinksMenu,
              );
            }}
          >
            Vendr Confluence Home
          </Link>
        </DropdownMenu.Item>
        <DropdownMenu.Item asChild>
          <Link
            to="https://vendr.okta.com/app/UserHome?session_hint=AUTHENTICATED"
            target="_blank"
            rel="noopener noreferrer"
            onClick={(event) => {
              segmentTrackLinkClick(
                event.currentTarget,
                ElementLocation.QuickLinksMenu,
              );
            }}
          >
            Okta Dash
          </Link>
        </DropdownMenu.Item>
        <DropdownMenu.Item asChild>
          <Link
            to="https://www.notion.so/a25c6624c4684b8a8fab9cfb1aa6bd21?v=e49fdcd633cc45ccbc5102f39c0b12a6&pvs=4"
            target="_blank"
            rel="noopener noreferrer"
            onClick={(event) => {
              segmentTrackLinkClick(
                event.currentTarget,
                ElementLocation.QuickLinksMenu,
              );
            }}
          >
            OKR One Pagers
          </Link>
        </DropdownMenu.Item>
        <DropdownMenu.Item asChild>
          <Link
            to="https://vendr-int.statusgator.app/"
            target="_blank"
            rel="noopener noreferrer"
            onClick={(event) => {
              segmentTrackLinkClick(
                event.currentTarget,
                ElementLocation.QuickLinksMenu,
              );
            }}
          >
            Vendr Internal Status
          </Link>
        </DropdownMenu.Item>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
}
