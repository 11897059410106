import { useState, useRef, useEffect } from "react";
import {
  useLoaderData,
  useRouteLoaderData,
  useSearchParams,
} from "@remix-run/react";
import { loader } from "~/routes/_app";
import type { loader as CompanyLoader } from "~/routes/_app.catalog.companies_._nav.$companyId";
import { Box, Flex, IconButton } from "@radix-ui/themes";
import { BackpackIcon, Cross1Icon } from "@radix-ui/react-icons";
import { cn } from "~/lib/utils";
import { TicketWidgetTabContent } from "./TicketWidgetTabContent";

export function TicketWidget() {
  const { user } = useLoaderData<typeof loader>();
  const { company, pageTasks = [] } =
    useRouteLoaderData<typeof CompanyLoader>(
      "routes/_app.catalog.companies_._nav.$companyId",
    ) || {};

  const [searchParams] = useSearchParams();
  const [isOpen, setIsOpen] = useState(false);
  const showTicketWidget = searchParams.get("feature") === "ticket-widget";

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const popupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node) &&
        isOpen
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  // TODO: Remove this once we are ready to launch the ticket widget
  if (!showTicketWidget) {
    return null;
  }

  return company ? (
    <Box right="4" bottom="4" position="fixed" className="z-10" ref={popupRef}>
      <Flex direction="column" align="end">
        <Box
          mb="4"
          className={cn("origin-bottom-right scale-0 opacity-0 -z-10", {
            "scale-100 opacity-100 z-10": isOpen,
          })}
          style={{
            transition:
              "width 200ms, height 200ms, max-height 200ms, transform 300ms cubic-bezier(0, 1.2, 1, 1), opacity 83ms ease-out",
          }}
        >
          <TicketWidgetTabContent
            company={company}
            tasks={pageTasks}
            user={user}
          />
        </Box>
        <IconButton
          size="4"
          radius="full"
          className={cn("cursor-pointer transition-transform duration-300", {
            "hover:scale-110": true,
          })}
          onClick={togglePopup}
        >
          {isOpen ? <Cross1Icon /> : <BackpackIcon />}
        </IconButton>
      </Flex>
    </Box>
  ) : null;
}
