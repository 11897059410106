import { Form, useLocation } from "@remix-run/react";
import { MoonIcon, SunIcon, DesktopIcon } from "@radix-ui/react-icons";
import { DropdownMenu, IconButton } from "@radix-ui/themes";
import { segmentTrackClick } from "~/lib/segment-utils";
import { ElementLocation, ElementType } from "~/services/segment-types";

export function ThemeToggle() {
  const location = useLocation();

  return (
    <DropdownMenu.Root
      onOpenChange={(open: boolean) => {
        if (open) {
          segmentTrackClick(
            "Theme Toggle",
            ElementType.Dropdown,
            ElementLocation.MainNavigation,
          );
        }
      }}
    >
      <DropdownMenu.Trigger>
        <IconButton
          className="IconButton"
          aria-label="Select theme"
          variant="ghost"
          color="gray"
        >
          <SunIcon
            width="16"
            height="16"
            style={{ display: "var(--theme-toggle-sun-icon-display)" }}
          />
          <MoonIcon
            width="16"
            height="16"
            style={{ display: "var(--theme-toggle-moon-icon-display)" }}
          />
        </IconButton>
      </DropdownMenu.Trigger>

      <DropdownMenu.Content className="DropdownMenuContent">
        <Form
          preventScrollReset
          replace
          action="/_actions/color-scheme"
          method="post"
          className="rt-Flex rt-r-fd-column"
        >
          <input
            type="hidden"
            name="returnTo"
            value={location.pathname + location.search}
          />
          <DropdownMenu.Item asChild>
            <button
              aria-label="Select light theme"
              value="light"
              name="colorScheme"
              onClick={() => {
                segmentTrackClick(
                  "Light",
                  ElementType.MenuItem,
                  ElementLocation.ThemeToggleMenu,
                );
              }}
            >
              <SunIcon width="16" height="16" />
              Light
            </button>
          </DropdownMenu.Item>
          <DropdownMenu.Item asChild>
            <button
              aria-label="Select dark theme"
              value="dark"
              name="colorScheme"
              onClick={() => {
                segmentTrackClick(
                  "Dark",
                  ElementType.MenuItem,
                  ElementLocation.ThemeToggleMenu,
                );
              }}
            >
              <MoonIcon width="16" height="16" />
              Dark
            </button>
          </DropdownMenu.Item>
          <DropdownMenu.Item asChild>
            <button
              aria-label="Select system theme"
              value="system"
              name="colorScheme"
              onClick={() => {
                segmentTrackClick(
                  "System",
                  ElementType.MenuItem,
                  ElementLocation.ThemeToggleMenu,
                );
              }}
            >
              <DesktopIcon width="16" height="16" />
              System
            </button>
          </DropdownMenu.Item>
        </Form>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
}
